@import '../../styles/variables.scss';

.MeetingType_basic {
  margin-top: 48px;
  .Header {
    margin-top: 5px;
    margin-bottom: 8px;
  }

  .TypesList {
    display: flex;
    .Type {
      max-width: 76px;
      display: flex;
      flex-direction: column;
      margin-right: 16px;
      align-items: center;
      justify-content: flex-start;
      text-align: center;
      &:last-of-type {
        margin-right: 0;
      }
      button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 64px;
        height: 64px;
        background-color: white;
        border-radius: 32px;
        margin-bottom: 8px;
        font-size: 24px;
        cursor: pointer;
        transition: font-size ease-in-out .25s;
        &:hover {
          font-size: 26.4px;
        }
      }
      &.Selected {
        button {
          background-color: $green-medium;
        }
      }
    }
  }
}

.MeetingType_external {
  margin-top: 48px;
  .Header {
    margin-top: 5px;
    margin-bottom: 8px;
  }

  .TypesList {
    display: flex;
    .Type {
      max-width: 76px;
      display: flex;
      flex-direction: column;
      margin-right: 16px;
      align-items: center;
      justify-content: flex-start;
      text-align: center;
      &:last-of-type {
        margin-right: 0;
      }
      button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 64px;
        height: 64px;
        background-color: white;
        border-radius: 32px;
        margin-bottom: 8px;
        font-size: 24px;
        cursor: pointer;
        transition: font-size ease-in-out .25s;
        box-shadow: 0px 4px 10px rgba(126, 98, 196, 0.1);
        &:hover {
          font-size: 26.4px;
        }
      }
      &.Selected {
        button {
          background-color: $SYV_Lilac_Dark;
          color: white;
        }
      }
    }
  }
}

@media (min-width: $breakpoint-medium) {
  .MeetingType_basic, .MeetingType_external {
    padding: 0 18px;
    margin-top: 0;
  }
}
