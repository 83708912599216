@import '../../styles/variables.scss';

.BookingInfo {
  .Header {
    margin-bottom: 6px;
  }
}

@media (min-width: $breakpoint-medium) {
  .BookingInfo {
    padding-right: 18px;
  }
}
