@import '../../styles/variables.scss';

.Header_basic {
  min-height: 64px;
  background-color: $black-medium;
  display: flex;
  align-items: center;
  justify-content: center;
  header {
    min-width: $min-width;
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 0 24px;
  }
}

.Header_external {
  min-height: 64px;
  background-color: white;
  display: flex;
  justify-content: center;
  header {
    min-width: $min-width;
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 0 24px;
    position: relative;
    .Buttons {
      position: absolute;
      right: 78px;
      top: 39px;
    }
  }
  .Logo {
    position: absolute;
    top: 24px;
    z-index: 1;
  }
}

@media (min-width: $breakpoint-medium) {
}

@media only screen and (max-width: $breakpoint-small) {
  .Header_external {
    header {
      .Logo {
        top: 50%;
        z-index: 1;
        transform: translateY(-50%);

        a {
          display: flex;
        }
      }
    }
  }
}

@media only screen and (min-width: $breakpoint-small) and (max-width: $breakpoint-medium) {
  .Header_basic {
    height: 96px;
    header {
      padding: 0 78px;
      max-width: $max-width;

      .Buttons {
        display: flex;
        margin-left: 48px;
      }
    }
  }

  .Header_external {
    min-height: 96px;
    header {
      padding: 0 78px;
      max-width: $max-width;

      .Buttons {
        display: flex;
        margin-left: 48px;
      }
    }
  }
}

@media only screen and (min-width: $breakpoint-medium) {
  .Header_basic {
    height: 96px;
    header {
      padding: 0 78px;
      max-width: $max-width;

      .Buttons {
        display: flex;
        margin-left: 48px;
      }
    }
  }

  .Header_external {
    min-height: 96px;
    header {
      padding: 0 78px;
      max-width: $max-width;

      .Buttons {
        display: flex;
        margin-left: 48px;
      }
    }
  }
}
