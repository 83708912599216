@import '../../../styles/variables.scss';

$buttonFont: 24px;

.RoundButton_basic {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  min-width: 48px;
  min-height: 48px;
  background-color: white;
  border-radius: 25px;
  cursor: pointer;
  font-size: $buttonFont;
  transition: font-size ease-in-out .25s;
  &:hover {
    font-size: 26.4px;
  }
  &.Bg-grey {
    background-color: $grey-super-light;
  }
  &.Selected {
    background-color: $green-medium;
    &:hover {
      font-size: $buttonFont;
    }
  }
  &:disabled {
    cursor:not-allowed;
    opacity: .5;
  }
}


.RoundButton_external {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  min-width: 48px;
  min-height: 48px;
  background-color: white;
  border-radius: 25px;
  cursor: pointer;
  font-size: $buttonFont;
  transition: font-size ease-in-out .25s;
  box-shadow: 0px 4px 10px rgba(126, 98, 196, 0.1);
  &:hover {
    font-size: 26.4px;
  }
  &.Bg-grey {
    background-color: $grey-super-light;
  }
  &.Selected {
    background-color: $SYV_Lilac_Dark;
    color: white;
    &:hover {
      font-size: $buttonFont;
    }
  }
  &:disabled {
    cursor:not-allowed;
    opacity: .5;
  }
}