@import '../../styles/variables.scss';

.InstructorSelectPopup_basic {
  position: absolute;
  top: 50%; /* position the top  edge of the element at the middle of the parent */
  left: 50%; /* position the left edge of the element at the middle of the parent */
  width: 100%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 24px;
  box-shadow: 0px 7px 8px rgba(0, 0, 0, 0.12), 0px 12px 17px rgba(0, 0, 0, 0.07),
    0px 5px 22px rgba(0, 0, 0, 0.06);
  z-index: 11;
  .Instructor {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    &:last-of-type {
      margin-bottom: 24px;
    }
    > span {
      margin-left: 12px;
      &.Grayscale {
        opacity: 0.5;
      }
    }
  }
}

.InstructorSelectPopup_external {
  position: absolute;
  top: 50%; /* position the top  edge of the element at the middle of the parent */
  left: 50%; /* position the left edge of the element at the middle of the parent */
  width: 100%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 10px;
  box-shadow: 0px 7px 8px rgba(0, 0, 0, 0.12), 0px 12px 17px rgba(0, 0, 0, 0.07),
    0px 5px 22px rgba(0, 0, 0, 0.06);
  z-index: 11;
  .Wrap {
    padding: 14px;
    background-color: $SYV_Lilac;
  }
  .Instructor {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    &:last-of-type {
      margin-bottom: 24px;
    }
    > span {
      margin-left: 12px;
      &.Grayscale {
        opacity: 0.5;
      }
    }
  }
}

@media (min-width: $breakpoint-small) {
  .InstructorSelectPopup_basic, .InstructorSelectPopup_external {
    width: auto;
  }
}
