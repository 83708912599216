@import '../../../../styles/variables.scss';

.EmailInput_basic {
  position: relative;
  input {
    &::placeholder {
      color: $grey-medium;
    }
    &:focus {
      border: 4px solid $grey-light;
    }
  }

  &.Invalid {
    input {
      border: 3px solid $red-medium;
    }
  }

  i {
    font-size: 18px;
    &.Empty {
      color: $grey-medium;
    }
  }

  .ValidationIcon {
    position: absolute;
    right: 15px;
    top: 38px;
  }

  .ErrorMessage {
    margin-left: 28px;
    margin-top: 8px;
    p {
      margin: 0;
      font-size: 14px;
      color: $red-medium;
    }
  }
}

.EmailInput_external {
  position: relative;
  input {
    &::placeholder {
      color: $grey-medium;
    }
    &:focus {
      border: 4px solid $grey-light;
    }
    box-shadow: 0px 4px 10px rgba(126, 98, 196, 0.1);
  }

  &.Invalid {
    input {
      border: 3px solid $red-medium;
    }
  }

  i {
    font-size: 18px;
    &.Empty {
      color: $grey-medium;
    }
  }

  .ValidationIcon {
    position: absolute;
    right: 15px;
    top: 38px;
  }

  .ErrorMessage {
    margin-left: 28px;
    margin-top: 8px;
    p {
      margin: 0;
      font-size: 14px;
      color: $red-medium;
    }
  }
}

