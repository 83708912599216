@import '../../styles/variables.scss';

.StepsWrap_basic {
  display: flex;
  justify-content: center;
  margin-top: 24px;

  .Steps {
    z-index: 1;
    max-width: 335px;
    display: flex;
    .Step {
      display: flex;
      flex-direction: column;
      width: 64px;
      align-items: center;
      button {
        width: 40px;
        height: 40px;
        border-radius: 24px;
        border: 3px solid white;
        background-color: $pastel-light;
        font-size: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 6px;
        cursor: pointer;
        font-weight: 700;
      }
      &.Selected {
        button {
          background-color: $green-medium;
          border: none;
        }
        
      }
      &.Passed {
        button {
          background-color: white;
          border: none;
        }
      }

      
    }
    .Line {
      width: 86px;
      height: 1px;
      margin-top: 19px;
      border-bottom: 2px solid $pastel-super-dark;
    }
  }
}

.StepsWrap_external {
  display: flex;
  justify-content: center;
  margin-top: 24px;

  .Steps {
    z-index: 1;
    max-width: 335px;
    display: flex;
    .Step {
      display: flex;
      flex-direction: column;
      width: 64px;
      align-items: center;
      button {
        font-family: 'Roboto', sans-serif;
        font-weight: 500;
        width: 40px;
        height: 40px;
        border-radius: 24px;
        border: 3px solid white;
        background-color: white;
        font-size: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
      &.Selected {
        button {
          color: white;
          background-color: $SYV_Lilac_Dark;
          border: none;
        }
        
      }
      &.Passed {
        button {
          background-color: white;
          border: none;
        }
      }

      
    }
    .Line {
      width: 86px;
      height: 1px;
      margin-top: 19px;
      border-bottom: 1.25px solid $SYV_Lilac_Dark;
      opacity: 0.5;
    }
  }
}

@media (min-width: $breakpoint-small) {
  .StepsWrap_basic {
      margin-top: 48px;
  }

  .StepsWrap_external {
    margin-top: 48px;
}
 }