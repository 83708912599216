.App {
  position: relative;
}

.backlit {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: black;
  opacity: 0.5;
  z-index: 100;
}
