@import '../../styles/variables.scss';

.LoginAdminButton_basic {
    color: white;
    font-size: 18px;
    text-decoration: none;
    display: inline-flex;
    margin-right: 48px;
    span:first-of-type {
        margin-right: 8px;
    }
}

.LoginAdminButton_external {
    color: $SYV_Black;
    font-size: 18px;
    text-decoration: none;
    display: inline-flex;
    margin-right: 48px;
    span:first-of-type {
        margin-right: 8px;
    }
}
