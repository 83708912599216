.BubbleSmall {
    position: absolute;
    top: 74px;
    right: 187px;
}

.BubbleMedium {
    position: absolute;
    bottom: -48px;
    right: -67px;
}

.BubbleLarge {
    position: absolute;
    top: 83px;
    left: -240px;
}