@import '../../styles/variables.scss';

.DateAndTime {
  position: relative;
  z-index: 2;
  .Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 6px;
    p {
      margin: 0;
      span {
        font-weight: 700;
      }
    }
  }

  .CalendarHeader {
    &.Muted {
      opacity: 0.5;
    }
  }

  .CalendarSlots {
    min-height: 296px;
    &.NoSlots {
      display: flex;
      align-items: center;
      p {
        width: 100%;
        text-align: center;
        font-size: 14px;
        font-weight: 700;
      }
    }
    &.Muted {
      opacity: 0.5;
    }
  }

  .Submit {
    margin-top: 16px;

    margin-bottom: 24px;
  }
  
  .NextFreeSlot {
    
  }
}

@media (min-width: $breakpoint-medium) {
  .DateAndTime {
    padding-left: 18px;
  }
}

.BackLight {
  position: absolute;
  width: 100%;
  height: 88%;
  background-color: black;
  opacity: 0;
  z-index: 10;
}
