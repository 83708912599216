@import '../../styles/variables.scss';

.Calendar_basic {
  &.Mobile {
    margin-bottom: 48px;
  }

  .DateRange {
    display: flex;
    justify-content: center;
    p {
      font-size: 14px;
      font-family: 'Campton-SemiBold', sans-serif;
    }
  }

  .Arrow {
    display: flex;
    align-items: center;
    button {
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 32px;
      min-height: 32px;
      background-color: white;
      border-radius: 20px;
      font-size: 14px;
      cursor: pointer;
      transition: font-size ease-in-out 0.25s;
      &:hover {
        font-size: 15.4px;
      }

      &:active {
        opacity: 0.5;
      }
      &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
      }
    }
    &.Left {
      justify-content: flex-start;
      button {
        span {
          margin-right: 2px;
        }
      }
    }
    &.Right {
      justify-content: flex-end;
      button {
        span {
          margin-left: 2px;
        }
      }
    }
  }

  .WeeksRow {
    position: relative;
    > div {
      display: flex;
      width: 100%;
      &.Mobile {
        width: auto;
        position: absolute;
        left: -24px;
        right: 0;
        margin-right: -24px;
      }
    }
    margin-top: 10px;
    .Day {
      min-height: 48px;
      background-color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      border-right: 1px solid $pastel-light;
      flex-direction: column;
      cursor: pointer;
      span {
        font-size: 14px;
        transition: font-size ease-in-out 0.25s;
      }
      &.Weekend {
        background-color: transparent;
        border-right: 1px solid white;
        border-top: 1px solid white;
        border-bottom: 1px solid white;
        &:last-of-type {
          border-right: none;
        }
      }
      &.Today {
        border-bottom: 2px solid $green-medium;
        span {
          font-family: 'Campton-SemiBold', sans-serif;
        }
      }
      &:hover {
        span {
          font-size: 15.4px;
        }
      }
      .Triangle {
        display: none;
      }
      &.Selected {
        background-color: $green-medium;
        .Triangle {
          display: block;
          min-width: 12px;
          min-height: 12px;
          background-color: $green-medium;
          transform: rotate(45deg);
          margin-bottom: -12px;
        }
      }
    }
  }
  &.Disabled {
    .DateRange {
      opacity: 0.3;
    }
    .Arrow {
      button {
        opacity: 0.3;
      }
    }
    .WeeksRow {
      opacity: 0.3;
      .Day {
        background: transparent;
        &.Selected {
          .Triangle {
            display: none;
          }
        }
        border-right: 1px solid white;
        border-top: 1px solid white;
        border-bottom: 1px solid white;
        &:first-of-type {
          border-left: 1px solid white;
        }
        &:last-of-type {
          border-right: 1px solid white;
        }
      }
    }
  }
}

.Calendar_external {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  filter: drop-shadow(0px 4px 10px rgba(126, 98, 196, 0.1));
  &.Mobile {
    margin-bottom: 48px;
  }

  .DateRange {
    display: flex;
    justify-content: center;
  }

  .Arrow {
    display: flex;
    align-items: center;
    button {
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 32px;
      min-height: 32px;
      background-color: white;
      border-radius: 20px;
      font-size: 14px;
      cursor: pointer;
      transition: font-size ease-in-out 0.25s;
      &:hover {
        font-size: 15.4px;
      }

      &:active {
        opacity: 0.5;
      }
      &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
      }
    }
    &.Left {
      justify-content: flex-start;
      button {
        span {
          margin-right: 2px;
        }
      }
    }
    &.Right {
      justify-content: flex-end;
      button {
        span {
          margin-left: 2px;
        }
      }
    }
  }

  .WeeksRow {
    position: relative;
    > div {
      display: flex;
      width: 100%;
      &.Mobile {
        width: auto;
        position: absolute;
        left: -24px;
        right: 0;
        margin-right: -24px;
      }
    }
    margin-top: 10px;
    .Day {
      min-height: 48px;
      background-color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      border-right: 1px solid $pastel-light;
      flex-direction: column;
      cursor: pointer;
      span {
        font-size: 14px;
        transition: font-size ease-in-out 0.25s;
      }
      &.Weekend {
        background-color: transparent;
        border-right: 1px solid white;
        border-top: 1px solid white;
        border-bottom: 1px solid white;
        &:last-of-type {
          border-right: none;
        }
      }
      &.Today {
        border-bottom: 2px solid $SYV_Lilac_Dark;
        span {
          font-family: 'Roboto-medium', sans-serif;
        }
      }
      &:not(.Selected):hover {
        span {
          font-size: 15.4px;
        }
      }
      .Triangle {
        display: none;
      }
      &.Selected {
        background-color: $SYV_Lilac_Dark;
        color: white;
        .Triangle {
          display: block;
          min-width: 12px;
          min-height: 12px;
          background-color: $SYV_Lilac_Dark;
          transform: rotate(45deg);
          margin-bottom: -12px;
        }
      }
    }
  }
  &.Disabled {
    .DateRange {
      opacity: 0.3;
    }
    .Arrow {
      button {
        opacity: 0.3;
      }
    }
    .WeeksRow {
      opacity: 0.3;
      .Day {
        background: transparent;
        &.Selected {
          background-color: $SYV_Lilac_Dark;
          // .Triangle {
          //   display: none;
          // }
        }
        border-right: 1px solid white;
        border-top: 1px solid white;
        border-bottom: 1px solid white;
        &:first-of-type {
          border-left: 1px solid white;
        }
        &:last-of-type {
          border-right: 1px solid white;
        }
      }
    }
  }
}
