@import '../../styles/variables.scss';

.Instructors {
  padding-right: 18px;
  margin-top: 32px !important;
  z-index: 1;
  .Header {
    margin-bottom: 8px;
  }
  .Instructor {
    z-index: 1;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    > span {
      cursor: pointer;
      margin-left: 12px;
    }
  }
  .MobileNextStepBtn {
    margin-top: 32px;
  }
}

@media (min-width: $breakpoint-medium) {
  .Instructors {
    z-index: 1;
    margin-top: 48px !important;
  }
}
