@import '../../styles/variables.scss';

.Lightwave {
  position: absolute;
  right: 0;
  top: 96px;
  max-width: 48%;
  z-index: 1;
}

.Bluewave {
  position: absolute;
  left: 0;
  max-width: 24%;
}

@media only screen and (max-width: $breakpoint-small) {
  .Bluewave {
    bottom: 400px;
  }

  .Lightwave {
    top: 64px;
  }
}

@media only screen and (min-width: $breakpoint-small) and (max-width: $breakpoint-medium) {
  .Bluewave {
    bottom: 425px;
  }
}

@media only screen and (min-width: $breakpoint-medium) {
  .Bluewave {
    bottom: 276px;
  }
}
