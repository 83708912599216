@import '../../node_modules/normalize-scss/sass/normalize/import-now';
@import './variables.scss';

@font-face {
  font-family: 'Campton-Book';
  src: url(./fonts/Campton-Book.woff);
}

@font-face {
  font-family: 'Campton-Medium';
  src: url(./fonts/Campton-Medium.woff);
}

@font-face {
  font-family: 'Campton-SemiBold';
  src: url(./fonts/Campton-SemiBold.woff);
}

@font-face {
  font-family: 'Campton-Bold';
  src: url(./fonts/Campton-Bold.woff);
}
// ---------------- External Theme --------------------//

@font-face {
  font-family: 'Roboto';
  src: url(./fonts/Roboto-Regular.ttf);
}
@font-face {
  font-family: 'Roboto-medium';
  src: url(./fonts/Roboto-Medium.ttf);
}
@font-face {
  font-family: 'Roboto-light';
  src: url(./fonts/Roboto-Light.ttf);
}

@font-face {
  font-family: 'Brandon-text';
  src: url(./fonts/Brandon-Text.otf);
}

html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video,
button {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

body {
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  body,
  a,
  button {
    font-family: 'Campton-Book', sans-serif;
    color: $black-medium;
  }
  &.external {
    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    body,
    a,
    button {
      font-family: 'Roboto', sans-serif;
      font-weight: 400;
    }
    main {
      background-color: $SYV_Lilac;
    }
  }
}

input,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

body,
html {
  margin: 0;
  padding: 0;
}
* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0) !important;
  -webkit-focus-ring-color: rgba(255, 255, 255, 0) !important;
  outline: none !important;
}

button {
  border: none;
}

h1 {
  font-weight: 700;
  font-size: 40px;
  line-height: 40px;
}

main {
  background-color: $pastel-light;
}

@media (min-width: $breakpoint-medium) {
  h1 {
    font-size: 80px;
    line-height: 92px;
  }
}
