.Duration,
.Instructor,
.Unit,
.MeetingTypeInfo,
.User,
.Notes {
  margin-top: 16px !important;
  .Info {
    display: flex;
    align-items: center;
    margin-top: 2px;
    span {
      min-width: 26px;
      margin-right: 4px;
      font-size: 18px;
      text-align: center;
    }
  }
}
.MeetingTypeInfo {
  .Info {
    span {
      // margin-right: 0;
      svg path {
        scale: 1;
      }

      &.PeopleSmallIcon {
        svg {
          width: 23px;
        }
      }
    }
  }
}

.User {
  .Info {
    display: flex;
    flex-direction: column;
    div {
      width: 100%;
      display: flex;
      margin-bottom: 4px;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}

.Notes {
  .Info {
    display: block;
  }
}
