@import '../../styles/variables.scss';

.LogoutButton_basic {
  color: white;
  button {
    color: white;
    margin-left: 8px;
    text-decoration: none;
    font-size: 18px;
    cursor: pointer;
    background: none;
    font-family: 'Campton-Book', sans-serif;
    padding: 0;
  }
}

.LogoutButton_external {
  button {
    color: $SYV_Black;
    margin-left: 8px;
    text-decoration: none;
    font-size: 18px;
    cursor: pointer;
    background: none;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    padding: 0;
  }
}
