@import '../../styles/variables.scss';

.ThankYouPage {
  display: flex;
  justify-content: center;
  margin-bottom: 36px;
  .ThankYouPageContainer {
    max-width: 845px;
    z-index: 1;
    .Header {
      margin: 48px 0 32px 0;
    }

    .Results {
      margin-bottom: 48px;
    }

    .Info {
      p {
        margin: 0;
        font-size: 18px;
        &:first-of-type {
          margin-bottom: 36px;
        }
      }
    }
  }
}

@media (min-width: $breakpoint-small) {
  .ThankYouPageContainer {
    .Header {
      margin: 128px 0 48px 0;
    }
    .Results {
      margin-bottom: 0;
    }
  }
}
