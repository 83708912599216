@import '../../styles/variables.scss';

.MobileMenuSwitch_basic {
  .ButtonGroup {
    width: 68px;
    display: flex;
    justify-content: flex-end;
    color: white;
    font-size: 18px;
    line-height: 24px;
  }
  &.Admin {
    .ButtonGroup {
      justify-content: space-between;
    }
  }
}

.MobileMenuSwitch_external {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  .ButtonGroup {
    width: 68px;
    display: flex;
    justify-content: flex-end;
    color: black;
    font-size: 18px;
    line-height: 24px;
  }
  &.Admin {
    .ButtonGroup {
      justify-content: space-between;
    }
  }
}

@media only screen and (max-width: $breakpoint-small) {
  .MobileMenuSwitch_external {
    right: 24px;
  }
}

@media only screen and (min-width: $breakpoint-small) and (max-width: $breakpoint-medium) {
  .MobileMenuSwitch_external {
    right: 78px;
  }
}

@media only screen and (min-width: $breakpoint-medium) {
  .MobileMenuSwitch_external {
    right: 78px;
  }
}