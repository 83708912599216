@import '../../styles/variables.scss';

.Welcome {
  margin-top: 45px;

  .Text {
    margin-top: 8px;
  }
}

@media (min-width: $breakpoint-small) {
  .Welcome {
    margin-top: 56px;
    .Text {
      margin-top: 16px;
    }
  }
}
