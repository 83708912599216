@import '../../../styles/variables.scss';

.Button_basic {
  &.Style-secondary {
    background-color: white;
    &:active {
      opacity: 0.5;
    }
  }
  &.Style-primary {
    background-color: $green-medium;
    &:active {
      background-color: $green-dark;
    }
  }
  &.Style-third {
    background-color: $green-light;
    &:active {
      background-color: $green-dark;
    }
  }
  min-height: 48px;
  width: 100%;
  border-radius: 24px;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  transition: font-size ease-in-out 0.25s;
  &:hover {
    font-size: 17.6px;
  }

  &:disabled {
    background-color: $black-medium-transparent;
    cursor: not-allowed;
    &:hover {
      font-size: 16px;
    }
    &:active {
      background-color: none;
    }
  }
}

.Button_external {
  &.Style-secondary {
    background-color: white;
    &:active {
      border: 3px solid #eae7f2;
    }
  }
  &.Style-primary {
    box-shadow: 0px 15px 18px -15px #5b32c4;
    background-color: $SYV_Lilac_Dark;
    color: white;
    &:active {
      background-color: $SYV_Lilac_Darkest;
    }
  }
  &.Style-third {
    box-shadow: 0px 15px 18px -15px $SYV_Lilac_Medium;
    background-color: $SYV_Lilac_Medium;
    color: white;
    &:active {
      background-color: $SYV_Lilac_Darkest;
    }
  }
  min-height: 48px;
  width: 100%;
  border-radius: 24px;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  transition: font-size ease-in-out 0.25s;
  &:hover {
    font-size: 17.6px;
  }

  &:disabled {
    background-color: $SYV_Lilac_Light95;
    cursor: not-allowed;
    box-shadow: none;
    color: $SYV_Black;
    &:hover {
      font-size: 16px;
    }
    &:active {
      background-color: none;
    }
  }
}
