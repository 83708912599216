@import '../../styles/variables.scss';

.GeneralError {
  min-height: 100vh;

  section {
    max-width: 405px;
  }
  .AlertSign {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    font-family: 'Campton-Bold', sans-serif;
    color: white;
    border-radius: 40px;
    padding-top: 10px;
    margin-bottom: 24px;
    width: 80px;
    height: 80px;
    background-color: $red-medium;
  }

  .Header {
      margin-bottom: 8px;
    span {
      color: $red-medium;
    }
  }
}
