@import '../../../styles/variables.scss';

.Checkbox_basic {
  &.NotValid {
    button {
      border: 3px solid $red-medium;
    }
  }
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    min-height: 32px;
    border-radius: 8px;
    background-color: white;
    border: 3px solid transparent;
    padding: 0;
    font-size: 18px;
    cursor: pointer;

    &:hover {
      border: 3px solid #d0d3d9;
    }
  }
}

.Checkbox_external {
  &.NotValid {
    button {
      border: 3px solid $red-medium;
    }
  }
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    min-height: 32px;
    border-radius: 8px;
    background-color: white;
    border: 3px solid transparent;
    padding: 0;
    font-size: 18px;
    cursor: pointer;
    box-shadow: 0px 4px 10px rgba(126, 98, 196, 0.1);
    &:hover {
      border: 3px solid #d0d3d9;
    }
  }
}
