@import '../../styles/variables.scss';

.CancellationPage_basic {
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: $pastel-light;

  .PageContent {
    display: flex;
    justify-content: center;
    margin-top: 128px;

    .Container {
      max-width: 845px;
      .Heading {
        margin-bottom: 48px;
      }
      .Text {
        margin-bottom: 24px;
      }

      .Button {
        max-width: 150px;
        margin-bottom: 48px;
      }
    }
  }
}

.CancellationPage_external {
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: $SYV_Lilac;

  .PageContent {
    display: flex;
    justify-content: center;
    margin-top: 128px;

    .Container {
      z-index: 1;
      max-width: 845px;
      .Heading {
        margin-bottom: 48px;
      }
      .Text {
        margin-bottom: 24px;
      }

      .Button {
        max-width: 150px;
        margin-bottom: 48px;
      }
    }
  }
}
