@import '../../styles/variables.scss';

.Footer_basic {
  p,
  h4,
  a {
    font-family: 'Campton', sans-serif;
  }
  margin-top: auto;
  background-color: $black-medium;
  display: flex;
  position: relative;
  justify-content: center;
  footer {
    p,
    h4,
    a {
      color: white;
      font-size: 18px;
    }
    max-width: $max-width;
    min-width: $min-width;
    // display: flex;
    width: 100%;
    // justify-content: space-between;
    padding: 48px 24px;
    flex-grow: 1;

    .Logo {
      max-width: 157px;
      svg {
        width: 100%;
      }
    }

    .GeneralInfo {
      margin-right: 35px;
      max-width: 408px;
    }

    .ContactInfo {
      > div {
        &:first-of-type {
          margin-top: 22px;
          margin-bottom: 22px;
        }
      }
    }

    .CookiesDisclaimer {
      margin-top: 24px;
      display: flex;
      p {
        font-size: 14px;
      }
    }
  }
}

.Footer_external {
  color: $SYV_Black;
  p,
  h4,
  a {
    font-family: 'Roboto', sans-serif;
  }
  margin-top: auto;
  background-color: white;
  display: flex;
  position: relative;
  justify-content: center;
  footer {
    p,
    h4,
    a {
      font-size: 18px;
    }
    max-width: $max-width;
    min-width: $min-width;
    // display: flex;
    width: 100%;
    // justify-content: space-between;
    padding: 48px 24px;
    flex-grow: 1;

    .Logo {
      max-width: 157px;
      svg {
        width: 100%;
      }
    }

    .GeneralInfo {
      margin-right: 35px;
      max-width: 408px;
    }

    .ContactInfo {
      > div {
        &:first-of-type {
          margin-top: 22px;
          margin-bottom: 22px;
        }
      }
    }

    .CookiesDisclaimer {
      margin-top: 24px;
      display: flex;
      p {
        font-size: 14px;
      }
    }
  }
}

@media (min-width: $breakpoint-medium) {
}

@media only screen and (max-width: $breakpoint-small) {
  .Footer_basic {
    height: 450px;
    footer {
      .Logo {
        max-width: 226px;
      }
    }
  }

  .Footer_external {
    height: 400px;
    footer {
      .Logo {
        max-width: 226px;
        margin-bottom: 24px;
      }
    }
  }
}

@media only screen and (min-width: $breakpoint-small) and (max-width: $breakpoint-medium) {
  .Footer_basic {
    height: 425px;
    footer {
      .Logo {
        max-width: 226px;
      }
    }
  }

  .Footer_external {
    height: 425px;
    footer {
      .Logo {
        max-width: 226px;
        margin-bottom: 24px;
      }
    }
  }
}

@media only screen and (min-width: $breakpoint-medium) {
  .Footer_basic {
    height: 276px;
    footer {
      p,
      h4,
      a {
        color: white;
        font-size: 14px;
      }
      padding: 48px 78px 24px 78px;

      .CookiesDisclaimer {
        margin-top: 48px;
        p {
          font-size: 12px;
        }
      }

      .ContactInfo {
        max-width: 210px;
        > div {
          &:first-of-type {
            margin-top: 0;
          }
        }
      }
      .Logo {
        max-width: 226px;
      }
    }
  }

  .Footer_external {
    height: 276px;
    color: $SYV_Black;
    footer {
      p,
      h4,
      a {
        font-size: 14px;
      }
      padding: 48px 78px 24px 78px;

      .CookiesDisclaimer {
        margin-top: 48px;
        p {
          font-size: 12px;
        }
      }

      .ContactInfo {
        max-width: 210px;
        > div {
          &:first-of-type {
            margin-top: 0;
          }
        }
      }
      .Logo {
        max-width: 226px;
      }
    }
  }
}
