@import '../../styles/variables.scss';

.Content {
  display: flex;
  justify-content: center;
  main {
    position: relative;
    max-width: $max-width;
    min-width: $min-width;
    width: 100%;
    padding: 0 24px;
    padding-bottom: 150px;
}
}

@media (min-width: $breakpoint-medium) {
    .Content {
        main {
            padding: 0 78px;
        }
    }
}

@media only screen and (max-width: $breakpoint-small) {
    .Content {
        main {
            padding-bottom: 222px;
            overflow: hidden;
        }
    }
}
