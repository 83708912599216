@import '../../../styles/variables.scss';

.H {
  display: block;
  color: $black-medium;
  font-family: 'Campton-SemiBold', sans-serif;
  line-height: 125%;
  &.s1 {
    font-size: 56px;
    &.Display {
      font-size: 80px;
      line-height: 110%;
      &.Mobile {
        font-size: 40px;
      }
    }
    &.Mobile {
      font-size: 24px;
    }
  }
  &.s2 {
    font-size: 24px;
    &.Mobile {
      font-size: 18px;
    }
  }
  &.s3 {
    font-size: 18px;
  }
  // EXTERNAL
  &.external {
    font-family: 'Brandon-text', sans-serif;
  }
}

.Text {
  margin: 0;
  color: $black-medium;
  &.Style-intro {
    font-family: 'Campton-Book', sans-serif;
    font-size: 24px;
    line-height: 150%;
    &.Mobile {
      font-size: 18px;
    }
  }
  &.Style-regular {
    font-family: 'Campton-Book', sans-serif;
    font-size: 18px;
    line-height: 150%;
    &.Bold {
      font-family: 'Campton-SemiBold', sans-serif;
    }
  }
  &.Style-regular-smaller {
    font-family: 'Campton-Book', sans-serif;
    font-size: 16px;
    line-height: 133%;
    &.Bold {
      font-family: 'Campton-SemiBold', sans-serif;
    }
  }
  &.Style-small {
    font-family: 'Campton-Medium', sans-serif;
    font-size: 14px;
    line-height: 150%;
    &.Bold {
      font-family: 'Campton-SemiBold', sans-serif;
    }
  }
  &.Style-legal {
    font-family: 'Campton-Medium', sans-serif;
    font-size: 12px;
    line-height: 125%;
  }
  &.Style-button {
    font-family: 'Campton-SemiBold', sans-serif;
    font-size: 16px;
    line-height: 115%;
  }

  &.external {
    font-family: 'Roboto-medium', sans-serif;
    &.Bold {
      font-family: 'Roboto-medium', sans-serif;
    }
  }
}
