@import '../styles/variables.scss';

.Layout_basic {
  display: flex;
  flex-direction: column;
  background-color: $pastel-light;
}

.Layout_external {
  display: flex;
  flex-direction: column;
  background-color: $SYV_Lilac;
}

@media (min-width: $breakpoint-medium) {
  .Layout_basic {
    min-height: 100vh;
  }

  .Layout_external {
    min-height: 100vh;
  }
}
