@import '../../../styles/variables.scss';

.RegisterForm {
  input,
  textarea {
    width: 100%;
    height: 48px;
    border-radius: 21.12px;
    border: 4px solid white;
    background: white;
    outline: none;
    font-size: 16px;
    font-family: 'Campton', sans-serif;
  }
  textarea {
    height: 122px;
  }

  .PhoneInput,
  .EmailInput {
    margin-bottom: 16px;
    margin-top: -16px;
    input {
      padding: 14.5px 39px 12.5px 44px;
    }
  }

  .EmailInput {
    margin-top: -22px;
  }

  .NotesInput {
    margin-top: -21px;
    textarea {
      padding: 16px 16px 64px 44px;
      resize: none;
    }
    i {
      top: 41px;
    }
  }

  i {
    position: relative;
    left: 16px;
  }

  .EmailInput {
    i {
      top: 37px;
    }
  }

  .NotesTips {
    display: flex;
    i {
      position: relative;
      top: 8px;
      left: 24px;
    }
    div {
      margin-left: 37px;
      margin-top: 22px;
      padding-right: 16px;
    }
  }

  .Terms {
    display: flex;
    margin-top: 48px;
    margin-bottom: 38px;
    div {
      margin-left: 8px;
    }
  }
}

@media (min-width: $breakpoint-small) {
  .RegisterForm {
    .NotesInput {
      margin-top: -23px;
    }
    .NotesTips {
      div {
        padding-right: 0;
        margin-left: 40px;
      }
      i {
        left: 30px;
      }
    }
  }
}
