@import '../../../../styles/variables.scss';

.PhoneInput_basic {
  position: relative;
  input {
    padding: 14.5px 39px 12.5px 44px;
    -moz-appearance: textfield;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
    &::placeholder {
      color: $grey-medium;
    }
    &:focus {
      border: 4px solid $grey-light;
    }
  }

  &.Invalid {
    input {
      border: 3px solid $red-medium;
    }
  }

  i {
    top: 38px;
    font-size: 18px;
    left: 18px;
    &.Empty {
      color: $grey-medium;
      
    }
  }

  .ValidationIcon {
    position: absolute;
    right: 15px;
    top: 38px;
  }

  .ErrorMessage {
    margin-left: 28px;
    margin-top: 8px;
    p {
      margin: 0;
      font-size: 14px;
      color: $red-medium;
    }
  }
}

.PhoneInput_external {
  position: relative;
  input {
    padding: 14.5px 39px 12.5px 44px;
    -moz-appearance: textfield;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
    &::placeholder {
      color: $grey-medium;
    }
    &:focus {
      border: 4px solid $grey-light;
    }
    box-shadow: 0px 4px 10px rgba(126, 98, 196, 0.1);
  }

  &.Invalid {
    input {
      border: 3px solid $red-medium;
    }
  }

  i {
    top: 38px;
    font-size: 18px;
    left: 18px;
    &.Empty {
      color: $grey-medium;
      
    }
  }

  .ValidationIcon {
    position: absolute;
    right: 15px;
    top: 38px;
  }

  .ErrorMessage {
    margin-left: 28px;
    margin-top: 8px;
    p {
      margin: 0;
      font-size: 14px;
      color: $red-medium;
    }
  }
}