@import '../../../styles/variables.scss';

.MobileMenuPopup {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 999;
  background-color: rgb(47 57 67 / 70%);
  // opacity: .5;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Window {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 87%;
  background-color: $black-medium;
  z-index: 1000;
  border-radius: 16px;
  text-align: center;
  color: white;
  .Button {
    margin: 32px 0;
    display: block;
    color: white;
    text-decoration: none;
    .Icon {
      font-size: 24px;
    }
    .Text {
      font-family: 'Roboto', sans-serif;
      font-size: 18px;
    }
  }
}
