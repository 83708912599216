@import '../../styles/variables.scss';

.CalendarSlots_basic {
  margin-top: 16px;
  .Slot {
    display: flex;
    justify-content: center;
    margin-bottom: 8px;
    button {
      width: 95%;
      min-height: 48px;
      border-radius: 28px;
      background-color: white;
      font-size: 14px;
      font-weight: 700;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      &:disabled {
        background-color: transparent;
        cursor: not-allowed;
      }
      &.MultiBtn {
        position: relative;
        .Icon {
          font-size: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          width: 32px;
          height: 32px;
          left: 8px;
          background-color: $pastel-light;
          border-radius: 25px;
        }
      }
      .Time {
        margin-top: 4px;
        font-size: 14px;
        transition: font-size 0.25s ease-in-out;
      }
      &:hover {
        .Time {
          font-size: 15.4px;
        }
      }
    }

    &.Selected {
      button {
        background-color: $green-medium;
      }
    }
  }
  &.Blocked {
    .Slot {
      button {
        opacity: 0.3;
      }
    }
  }
}

.CalendarSlots_external {
  margin-top: 16px;
  .Slot {
    display: flex;
    justify-content: center;
    margin-bottom: 8px;
    button {
      width: 95%;
      min-height: 48px;
      border-radius: 28px;
      background-color: white;
      box-shadow: 0px 4px 10px rgba(126, 98, 196, 0.1);
      font-size: 14px;
      font-weight: 700;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      &:disabled {
        background-color: transparent;
        cursor: not-allowed;
        box-shadow: none;
      }
      &:active {
        border: 3px solid #eae7f2;
      }
      &.MultiBtn {
        position: relative;
        .Icon {
          font-size: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          width: 32px;
          height: 32px;
          left: 8px;
          background-color: $SYV_Lilac_Light;
          border-radius: 25px;
        }
        &:disabled {
          .Icon {
            background-color: #fcfbfe;
          }
        }
      }
      .Time {
        margin-top: 4px;
        font-size: 14px;
        transition: font-size 0.25s ease-in-out;
      }
      &:hover {
        .Time {
          font-size: 15.4px;
        }
      }
    }

    &.Selected {
      button {
        background-color: $SYV_Lilac_Dark;
        color: white;
        &:active {
          background-color: $SYV_Lilac_Darkest;
        }
        &.MultiBtn {
          .Icon {
            color: $SYV_Black;
          }
        }
      }
    }
  }
  &.Blocked {
    .Slot {
      button {
        opacity: 0.3;
      }
    }
  }
}
