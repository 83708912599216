// View Breakpoints
$breakpoint-small: 600px;
$breakpoint-medium: 960px;
$breakpoint-large: 1280px;
$breakpoint-x-large: 1920px;

// Colours

$green-light: #7dffbe;
$green-medium: #00e1a0;
$green-dark: #008566;

$black-light: #575c67;
$black-medium: #2f3943;
$black-dark: #202a34;

$SYV_Black: #2c3132;
$SYV_Lilac_Dark: #5b32c4;
$SYV_Lilac_Darkest: #321380;
$SYV_Lilac_Medium: #a087f1;
$SYV_Lilac: #f8f7fc;
$SYV_Lilac_Light95: #eae7f2;
$SYV_Lilac_Light: #ded6f3;

$black-medium-transparent: rgba(47, 57, 67, 0.1);

$pastel-light: #fdebe7;
$pastel-medium: #f7dad1;
$pastel-super-dark: #e5a795;

$grey-super-light: #edeff2;
$grey-light: #d0d3d9;
$grey-medium: #8d9199;

$red-medium: #e13600;

// Container size boundaries

$min-width: 375px;
$max-width: 1440px;

@media only screen and (max-width: $breakpoint-small) {
  // Rules
}

@media only screen and (min-width: $breakpoint-small) and (max-width: $breakpoint-medium) {
  // Rules
}

@media only screen and (min-width: $breakpoint-medium) {
  // Rules
}
