@import '../../styles/variables.scss';

.ServiceTypes {
  padding-right: 18px;
  .Header {
    margin-bottom: 6px;
  }
  .Service {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    > span {
      cursor: pointer;
      margin-left: 12px;
      margin-right: 32px;
    }
  }
}

@media (min-width: $breakpoint-medium) {
  .ServiceTypes {
    .Service {
      > span {
        white-space: nowrap;
      }
    }
  }
}
