@import '../../styles/variables.scss';

.Login {
  display: flex;
  justify-content: center;
  height: 85vh;
  flex-direction: column;
}

.LoginButton {
  display: flex;
  justify-content: center;
  > div {
    width: 327px;
  }
  .LoginAcademedia {
    margin-top: 20px;
  }
}

.WelcomeText {
  text-align: center;
}

@media (min-width: $breakpoint-medium) {
  .Login {
    height: 100vh;
  }
  .LoginButton {
    > div {
      width: 405px;
    }
  }
}
