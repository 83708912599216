@import '../../styles/variables.scss';

.StepsContainer {
    margin-top: 48px;
    margin-bottom: 96px;
}

@media (min-width: $breakpoint-small) {
    .StepsContainer {
        margin-top: 64px;
        margin-bottom: 0;
    }
  }