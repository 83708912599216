@import '../../styles/variables.scss';

.RegisterDetails {
  margin-top: 48px;
  margin-bottom: 48px;
  .Header {
    margin-top: 5px;
    margin-bottom: 8px;
  }

  .User {
    margin-bottom: 24px;
    div {
      display: flex;
      align-items: center;
      span:first-of-type{
        font-size: 18px;
        width: 24px;
        text-align: center;
        margin-right: 5px;
        margin-left: 10px;
      }
      span:last-of-type {
        margin-left: 7px;
      }
    }
  }
}

@media (min-width: $breakpoint-medium) {
  .RegisterDetails {
    padding-right: 18px;
  }
}

@media (min-width: $breakpoint-large) {
  .RegisterDetails {
    margin-top: 0;
    padding-left: 18px;
    padding-right: 0;
  }
}
